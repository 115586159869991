<template>
	<section class="wrapper-section" v-background="background">
		<base-container>
			<cms-text class="title" :props="{size: 'xl', color: 'white', weight: 'bold', uppercase: true}" :value="`${prefix}.title`" v-animate="`slideLeft`"/>
			<div class="items">
                <div v-for="(item, index) in value.advantages_schema" :key="index" v-animate="`opacity`">
                    <div class="item-wrapper odd" v-if="index % 2 !== 1">
                        <div class="item">
                            <base-svg :name="item.advantages_svg"/>
                            <cms-text class="description" :props="{size: 'md', weight: 'bold', uppercase: true, color: 'gray'}" :value="`${prefix}.advantages_schema.${index}.advantages_text`" />
                        </div>
                    </div>
                    <div class="item-wrapper even" v-else>
                        <div class="item">
                            <base-svg :name="item.advantages_svg"/>
                            <cms-text class="description" :props="{size: 'md', weight: 'bold', uppercase: true, color: 'gray'}" :value="`${prefix}.advantages_schema.${index}.advantages_text`" />
                        </div>
                    </div>
                </div>
			</div>
		</base-container>
	</section>
</template>
<script>

export default {
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		background () {
			const path = 'products/product-section-bg.jpg'

			return {
				src: path,
				breakpoints: {
					base: { width: 360, height: 650 },
					lg: { width: 825, height: 451 },
					xl: { width: 1920, height: 515 }
				}
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.wrapper-section {
	position: relative;
	padding: 4rem 0;
    @include media-breakpoint-up(xl) {
        padding: 8rem 0;
    }
	z-index: 1;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba($secondary, 0.95);
		z-index: -1;
	}
	::v-deep .title {
		margin-bottom: 6rem;
	}
}
.items {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 4rem;
	.item-wrapper {
		position: relative;
		display: flex;
		gap: 3rem;
		@include media-breakpoint-up(xl) {
			&::before, &::after {
				content: '';
				display: block;
				width: 160px;
				height: 90px;
				flex-shrink: 0;
			}
			&.odd {
				&::after {
					margin-top: auto;
					margin-right: 3rem;
					border-top: 2px solid $white;
					border-right: 2px solid $white;
				}
				&::before {
					content: none;
				}
			}
			&.even {
				&::after {
					content: none;
				}
				&::before {
					margin-bottom: auto;
					margin-left: 3rem;
					border-bottom: 2px solid $white;
					border-left: 2px solid $white;
				}
			}
		}
		.item {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			border-radius: 5px;
			background-color: $white;
			padding: 3rem;
			@include media-breakpoint-up(xl) {
				flex-direction: row;
				height: 180px;
				padding: 5.4rem 12rem 5.4rem 6rem;
			&::before {
				content: '';
				position: absolute;
				top: 28px;
				bottom: 28px;
				left: 180px;
				width: 1px;
				background-color: rgba($gray-500, .2);
			}
			}
		}
		::v-deep .svg-icon {
			margin-bottom: 2rem;
			width: 55px;
			max-height: 70px;

			path {
				fill: $green-300;
			}
			@include media-breakpoint-up(xl) {
				margin-bottom: 0;
				flex-shrink: 0;
				margin-right: 12rem;

			}
		}
		::v-deep .description {
			margin-bottom: 0;
            text-align: center;
            @include media-breakpoint-up(xl){
                text-align: initial;
            }
		}
	}
}
</style>
